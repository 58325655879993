import format from "date-fns/format";
import ReactJson from 'react-json-view';
import differenceWith from 'lodash/differenceWith';
import { detailedDiff } from 'deep-object-diff';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";

import IProduct from "models/product";

interface IExportHistoryDialogProps {
  open?: boolean;
  onClose: () => void;
  product?: IProduct;
  exportType?: string;
}

const useStyles = makeStyles(() => ({
  paper: { minWidth: 900, minHeight: 650 }
}));

export const ExportHistoryDialog = ({ open, product, onClose, exportType }: IExportHistoryDialogProps) => {
  const classes = useStyles();
  let exportValue = "EXPORT";
  if (exportType === "ica") {
    exportValue = "ICAEXPORT";
  }
  const versions = product?.versions?.filter((x) => x.type === exportValue).sort((a, b) => b.createdAt - a.createdAt);

  return (
    <Dialog open={!!open} classes={{ paper: classes.paper }}>
      <DialogTitle>Export history for {product?.title}</DialogTitle>
      <DialogContent>
        {(!versions || versions.length === 0) && (
          <span style={{color: 'red'}}>No export history...</span>
        )}
        {versions && versions.length > 0 && (
          <Table>
            {/* <TableHead>
              <TableRow>
                <TableCell>Export Date</TableCell>
              </TableRow>
            </TableHead> */}
            <TableBody>
              {versions.map((version, index) => {
                const next = versions && versions[index + 1];
                let json1: any = undefined;
                let json2: any = undefined;
                let date1;
                let date2;
                if (version?.data) {
                  json1 = version.data;
                  date1 = format(parseInt(version.createdAt), 'Y-MM-dd HH:mm');
                  if (next?.data) {
                    json2 = next?.data;
                    date2 = format(parseInt(next.createdAt), 'Y-MM-dd HH:mm');
                  }
                } else {
                  if (next?.data) {
                    json1 = next.data;
                    date1 = format(parseInt(next.createdAt), 'Y-MM-dd HH:mm');
                  }
                }
                
                if (json1 && (!json1.id || json1.id.trim() === '')) json1.id = product?.id;
                if (json2 && (!json2.id || json2.id.trim() === '')) json2.id = product?.id;
                
                if (json1 && json2) {
                  const diff1: any = detailedDiff(json2, json1);
                  const diff2: any = detailedDiff(json1, json2);
                  
                  json1 = diff1.added;
                  json1 = {...json1, ...diff1.updated};

                  json2 = diff2.added;
                  json2 = {...json2, ...diff2.updated};
                }

                const map = (json: IProduct) => {
                  if (json) {
                    const result = {...json};

                    // if (json.id) result.id = json.id.toString();
                    if (json.createdAt) result.createdAt = format(parseInt(json.createdAt), 'Y-MM-dd HH:mm:ss');
                    if (json.updatedAt) result.updatedAt = format(parseInt(json.updatedAt), 'Y-MM-dd HH:mm:ss');
                    
                    return result;
                  }
                  return undefined;
                };
                json1 = map(json1);
                json2 = map(json2);

                return (
                  <>
                    {/* <TableRow>
                      <TableCell>{version?.createdAt && format(parseInt(version.createdAt), 'Y-MM-dd HH:mm')}</TableCell>
                    </TableRow> */}
                    <TableRow>
                      <TableCell>
                        <Grid container={true} style={{padding: 10}}>
                          <Grid item={true} xs={6}>
                            {json1 && (
                              <ReactJson
                                src={json1}
                                enableClipboard={false}
                                iconStyle="triangle"
                                displayDataTypes={false}
                                displayObjectSize={true}
                                indentWidth={4}
                                collapsed={false}
                                // theme="monokai"
                                name={date1}
                                collapseStringsAfterLength={50}
                                quotesOnKeys={false}                          
                              />
                            )}
                          </Grid>
                          <Grid item={true} xs={6}>
                            {json2 && (<ReactJson
                              src={json2}
                              enableClipboard={false}
                              iconStyle="triangle"
                              displayDataTypes={false}
                              displayObjectSize={true}
                              indentWidth={4}
                              collapsed={false}
                              // theme="monokai"
                              name={date2}
                              collapseStringsAfterLength={50}
                              quotesOnKeys={false}                          
                            />)}
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  </>
              );
              })}
            </TableBody>
          </Table>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportHistoryDialog;
