import { img, thumb, thumbInner } from "./styles";

interface IThumbImageProps {
  imageUrl: string;
}

export const ThumbImage = ({ imageUrl }: IThumbImageProps) => {
  return (
    <div style={thumb} key={imageUrl}>
      <div style={thumbInner}>
        <img src={imageUrl} style={img} alt="preview" />
      </div>
    </div>
  );
};

export default ThumbImage;
