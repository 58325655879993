import { FC } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import DoneIcon from '@material-ui/icons/Done';

import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';

import dangerousOutlinedIconImg from 'assets/icons/DangerousOutlined.png';
import { makeStyles } from '@material-ui/core';
import useViewport from 'hooks/useViewports';
import TagLabel from 'components/TagLabel';

const useStyles = makeStyles(() => ({
  treeItem: {
    '& > .MuiTreeItem-content .MuiTreeItem-label': {
      backgroundColor: 'transparent !important',
      fontWeight: 600,
      fontSize: 14,
      '& > u': {
        textDecoration: 'none',
      },
    },
    '& > .MuiTreeItem-group': {
      marginLeft: 0,
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    background: '#E5F3F2',
    borderRadius: 8,
    padding: 12,
    marginTop: 8,
  },
  exampleCardWrapper: {
    display: 'flex',
    gap: 10,
    flexWrap: 'wrap',
  },
  exampleCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    background: '#fff',
    borderRadius: 8,
    padding: 12,
    gap: 12,
  },
}));

interface UploadImageGuideExampleProps {
  tipTitle: string;
  tipList: string[];
  correctImageSrc: string;
  wrongImageSrc: string;
}

const UploadImageGuideExample: FC<UploadImageGuideExampleProps> = ({
  tipTitle,
  tipList,
  correctImageSrc,
  wrongImageSrc,
}) => {
  const { smUp } = useViewport();
  const classes = useStyles();

  return (
    <TreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
      <TreeItem className={classes.treeItem} nodeId="root" label={<u>Se exempel och tips</u>}>
        <div className={classes.wrapper}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
            <InfoOutlinedIcon fontSize="medium" />
            <span style={{ fontWeight: 600, fontSize: 16 }}>{tipTitle}</span>
          </div>

          <ul style={{ listStyle: 'disc', paddingLeft: 20 }}>
            {tipList.map((tip, index) => (
              <li key={index}>
                <span style={{ paddingLeft: 4 }}>{tip}</span>
              </li>
            ))}
          </ul>

          <div className={classes.exampleCardWrapper}>
            <div className={classes.exampleCard}>
              <TagLabel
                type="success"
                label="Bra exempel"
                icon={<DoneIcon fontSize="small" />}
              />

              <img
                src={correctImageSrc}
                alt="Bra exempel"
                style={{ maxHeight: smUp ? 350 : undefined, width: smUp ? undefined : '100%' }}
              />
            </div>

            <div className={classes.exampleCard}>
              <TagLabel
                type="error"
                label="Dåligt exempel"
                icon={<img src={dangerousOutlinedIconImg} alt="" style={{ height: 22 }} />}
              />

              <img
                src={wrongImageSrc}
                alt="Dåligt exempel"
                style={{ maxHeight: smUp ? 350 : undefined, width: smUp ? undefined : '100%' }}
              />
            </div>
          </div>
        </div>
      </TreeItem>
    </TreeView>
  );
};

export default UploadImageGuideExample;
