import React, { useContext, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { uniqBy, isEmpty, path } from 'ramda';
import clsx from 'clsx';

import InputLabel from 'components/inputfields/InputLabel';
import { KeycloakContext } from 'components/Secured';
import UploadImageGuideLabels from 'components/product/ProductUpdateForm/FillProductByImage/UploadImageGuideLabels';
import UploadImageGuideExample from 'components/product/ProductUpdateForm/FillProductByImage/UploadImageGuideExample';

import { dimensionsCheck, processImage } from 'components/fileuploader/ImageUploader2';
import ThumbImage from 'components/fileuploader/thumb-image';

import useViewport from 'hooks/useViewports';

import productEcommerceIcon from 'assets/images/product-ecommerce-icon.png';
import correctExampleImg from 'assets/images/upload-image-product-correct-example.png';
import wrongExampleImg from 'assets/images/upload-image-product-wrong-example.png';

import { thumbsContainer } from './styles';

const ImageDropZone = ({
  newUi,
  images,
  containerStyle,
  onImageDropped,
  withLabel,
  labelOptions,
  multiple,
  withOutImagePreview,
  disabled, 
  withDimensionCheck,
  onImagesRejected,
  setLoadingTiff,
  children,
}) => {
  const { smUp } = useViewport();
  const keycloakCtx = useContext(KeycloakContext);
  const token = path(['keycloak', 'token'], keycloakCtx);
  const isStore = keycloakCtx.keycloak.hasResourceRole('store');
  const isProducer = keycloakCtx.keycloak.hasResourceRole('producer');
  const isProductionMode = process.env.REACT_APP_AUTH_ENV === 'prod';
  console.log('AUTH_ENV', process.env.REACT_APP_AUTH_ENV);

  const keepRef = useRef({ isImageSorterDraging: false });
  
  const [thumbs, setThumbs] = useState([]);
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    // accept: 'image/*',
    acceptedFiles: ["image/jpeg", "image/png", "image/tiff"],
    multiple,
    disabled,
    onDrop: async acceptedFiles => {
      let images = acceptedFiles.filter((image) => image.type !== 'image/gif');
      if (!images.length) {
        return;
      }

      if (withDimensionCheck) {
        const { acceptedByDimension, rejectedByDimension } = await dimensionsCheck(acceptedFiles);
        console.log('!!!acceptedByDimension', acceptedByDimension);
        console.log('!!!rejectedByDimension', rejectedByDimension);
        if(!isEmpty(rejectedByDimension)) onImagesRejected(rejectedByDimension);
        if(isEmpty(acceptedByDimension)) return;
        images = acceptedByDimension;
      }

      // for (let i = 0; i < images.length; i++) {
      //   images[i] = await setDpi(images[i]);
      // }
      
      setLoadingTiff(true);
      images = await Promise.all(
        images.map(async (image) => {
          let newImage = await processImage(image, token, !isProductionMode);
          return newImage;
        })
      );


      setLoadingTiff(false);
      images = images.map((image) => {
        const name = (+new Date()).toString() + '_' + image.name;
        const file = new File([image], name, {type: image.type});
        file.path = name;
        return file;
      });
      const allFiles = uniqBy(f => f.path, [...files, ...images]);
      const allThumbs = await Promise.all(allFiles.map(async (f) => {
          return URL.createObjectURL(f);
      }));
      // const allThumbs = await Promise.all(allFilesT.map((f) => previewFile(f)));

      console.log('!!!allFiles', allFiles);
      console.log('!!!allThumbs', allThumbs);
      setFiles(allFiles);
      setThumbs(allThumbs);
      onImageDropped(allFiles);
    },
  });

  const thumbsPictures = thumbs.map(t => (
    <ThumbImage imageUrl={t} />
  ));

  React.useEffect(
    () => () => {
      thumbs.forEach(thumb => URL.revokeObjectURL(thumb));
    },
    [thumbs]
  );

  React.useEffect(() => {
    const handleImageSorterDragStart = () => {
      keepRef.current.isImageSorterDraging = true;
    };
    const handleImageSorterDragEnd = () => {
      keepRef.current.isImageSorterDraging = false;
    };

    document.addEventListener('ImageSorter_dragStart', handleImageSorterDragStart);
    document.addEventListener('ImageSorter_dragEnd', handleImageSorterDragEnd);

    return () => {
      document.removeEventListener('ImageSorter_dragStart', handleImageSorterDragStart);
      document.removeEventListener('ImageSorter_dragEnd', handleImageSorterDragEnd);
    }
  });

  const rootProps = getRootProps(
    newUi
      ? {
          className: clsx('dropzone-custom product-image-bg', !!images?.length && 'has-item'),
          style: { minHeight: '160px' },
        }
      : { className: 'dropzone' }
  );

  return (
    <section className="container" style={containerStyle}>
      {withLabel && <InputLabel { ...labelOptions } />}
      
      {newUi && (isStore || isProducer) && (
        <UploadImageGuideLabels
          title='Riktlinjer för bra produktbilder'
          labels={['Ljus bakgrund', 'Bra belysning', 'Bra skärpa', 'Hela produkten syns med marginal']}
        />
      )}

      <div
        {...rootProps}
        onClick={(event) => {
          if (!keepRef.current.isImageSorterDraging) {
            rootProps.onClick(event);
          }
        }}
      >
        <input {...getInputProps()} />
        {newUi ? (
          <>
            <div className='dropzone-text'>
              <div className='dropzone-icon'>
                <img src={productEcommerceIcon} alt='' />
              </div>
              {!images?.length && (
                <div>
                  {smUp
                    ? 'Dra och släpp en bild här eller klicka för att välja en från din dator (Stödda format: JPG, JPEG, PNG, TIF. Minsta storlek: 900x900px).'
                    : 'Klicka här för att öppna kameran eller välja en bild från ditt bibliotek'}
                </div>
              )}
            </div>
            <div
              style={{ maxWidth: '100%', overflow: 'auto' }}
              onClick={(event) => event.stopPropagation()}
            >
              {children}
            </div>
          </>
        ) : (
          <span>Dra en bild till det här området eller klicka för att välja en från din dator!</span>
        )}
      </div>
      {!withOutImagePreview && <aside style={thumbsContainer}>{thumbsPictures}</aside>}

      {newUi && (isStore || isProducer) && (
        <UploadImageGuideExample
          tipTitle='Tips för bättre produktbilder'
          tipList={[
            'Fotografera i dagsljus eller med bra belysning',
            'Se till att hela produkten är skarp och i fokus',
            'Hela produkten ska synas med marginal',
            'Undvik störande bakgrund (lägg t.ex. produkten på ett vitt papper)',
          ]}
          correctImageSrc={correctExampleImg}
          wrongImageSrc={wrongExampleImg}
        />
      )}
    </section>
  );
};

export default ImageDropZone;
