import { useMemo, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import {
  Box,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
  TablePagination,
  CircularProgress,
  makeStyles,
  Card,
  TextField,
} from '@material-ui/core';
import useSearchParams from 'hooks/useSearchParams';

import { GET_PRODUCERS_PRODUCTS } from 'graphql/queries';
import HighlightedText from 'components/highlightedText/HighlightedText';

// Styles
const useStyles = makeStyles(theme => ({
  title: {
    margin: '12px 16px 0 16px',
  },
  loader: {
    position: 'absolute',
    top: 56,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(255, 255, 255, 0.7)',
  },
}));

type TProducerProducts = {
  producerId: string;
  producerName: string;
  isStore?: boolean;
  productsCount: number;
};

type TProducerProductsData = {
  total: number;
  items: TProducerProducts[];
};

const BulkReview = () => {
  const classes = useStyles();

  const { searchParams, updateSearchParams } = useSearchParams();

  const [queryVariables, setQueryVariables] = useState({
    textQuery: searchParams.search || '',
    pagePagination: { page: 1, limit: 10 },
  });

  const { loading, data: producersProductsResponse } = useQuery(GET_PRODUCERS_PRODUCTS, {
    variables: queryVariables,
  });

  const { total, items } = useMemo<TProducerProductsData>(() => {
    if (!producersProductsResponse?.producersProducts) {
      return { total: 0, items: [] };
    }
    return producersProductsResponse.producersProducts;
  }, [producersProductsResponse]);

  return (
    <Card>
      <h3 style={{ margin: 16 }}>Butiker och Leverantörer</h3>
      <Box px={2} maxWidth={400}>
        <TextField
          fullWidth
          label="Sök"
          placeholder="Namn..."
          variant="outlined"
          value={queryVariables.textQuery}
          onChange={e => {
            setQueryVariables(state => ({
              textQuery: e.target.value,
              pagePagination: { ...state.pagePagination, page: 1 },
            }));
            updateSearchParams({ search: e.target.value });
          }}
        />
      </Box>
      <TableContainer style={{ position: 'relative' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={200} style={{ fontWeight: 'bold' }}>
                Namn
              </TableCell>
              <TableCell width={200} style={{ fontWeight: 'bold' }}>
                Typ
              </TableCell>
              <TableCell width={200} style={{ fontWeight: 'bold' }}>
                Antal produkter att godkänna
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {items.map(item => {
              return (
                <TableRow key={item.producerId} hover role="checkbox" tabIndex={-1}>
                  <TableCell>
                    <HighlightedText text={item.producerName || ''} searchString={queryVariables.textQuery} />
                  </TableCell>
                  <TableCell>{item.isStore ? 'Butik' : 'Leverantör'}</TableCell>
                  <TableCell>{item.productsCount}</TableCell>
                </TableRow>
              );
            })}

            {loading && !items.length && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Box display="flex" justifyContent="center" py={10} height="100%">
                    <CircularProgress />
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        {loading && !!items.length && (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        )}
      </TableContainer>

      <TablePagination
        component="div"
        count={total}
        rowsPerPage={queryVariables.pagePagination.limit}
        page={queryVariables.pagePagination.page - 1}
        onPageChange={(_, pageIndex) => {
          setQueryVariables(state => ({
            ...state,
            pagePagination: { ...state.pagePagination, page: pageIndex + 1 },
          }));
        }}
        onRowsPerPageChange={event => {
          setQueryVariables(state => ({
            ...state,
            pagePagination: { page: 1, limit: +event.target.value },
          }));
        }}
      />
    </Card>
  );
};

export default BulkReview;
