import { FC, ReactNode } from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  label: {
    display: 'inline-flex',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: 12,
    gap: 4,
    borderRadius: 4,
    padding: '4px 8px 4px 4px',
  },
  labelSpace: {
    margin: '4px 0',
  },
  labelClickable: {
    cursor: 'pointer',
  },
  labelInfo: {
    color: '#4050B5',
    background: '#E8EAF6',
  },
  labelSuccess: {
    color: '#3A6F6E',
    background: '#E7F1F1',
  },
  labelError: {
    color: '#B71C1C',
    background: '#FFEBEE',
  },
}));

interface TagLabelProps {
  space?: boolean;
  icon?: ReactNode;
  type: 'info' | 'success' | 'error';
  label?: string;
  children?: ReactNode;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const TagLabel: FC<TagLabelProps> = ({ space, type, style, icon, label, children, onClick }) => {
  const classes = useStyles();

  return (
    <div>
      <div
        className={clsx(
          classes.label,
          onClick && classes.labelClickable,
          space && classes.labelSpace,
          type === 'info' && classes.labelInfo,
          type === 'success' && classes.labelSuccess,
          type === 'error' && classes.labelError
        )}
        style={style}
        onClick={onClick}
      >
        {icon}
        <span style={{ verticalAlign: 'middle' }}>{label || children}</span>
      </div>
    </div>
  );
};

export default TagLabel;
