import format from "date-fns/format";
import { path } from 'ramda';

import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";

import { SUPERUSER_APPROVAL } from "components/constants-ts";
import { getBrand, getBrandFood } from "components/product/ProductUpdateForm/utils";
import IProduct from "models/product";
import { isDefined } from "utils/helpers-ts";
import { IMAGE_SIZES, getImageWithSizeSuffix } from "utils/images2";
import { isNonFoodProduct } from "utils/products";
import { GreenBlinkingBadge, RedBlinkingBadge } from "components/badges/badges";
import COLORS from "constants/colors";
import HighlightedText from "components/highlightedText/HighlightedText";

interface IHistoryItemProps {
  textQuery?: string;
  product: IProduct;
  setOpen: (value: boolean) => void;
  setCurrentProduct: (value?: IProduct) => void;
  exportType?: string;
}

export const HistoryItem = ({ textQuery, product, setOpen, setCurrentProduct, exportType }: IHistoryItemProps) => {
  let exportValue = "EXPORT";
  if (exportType === "ica") {
    exportValue = "ICAEXPORT";
  }
  const { versions } = product;
  const historyLength =versions?.filter(x => x.type === exportValue).length || 0;
  const brand = isNonFoodProduct(product) ? getBrand(product, product.producer) : getBrandFood(product, product.producer);
  const image = product.image_src && isDefined(product.image_src) ? getImageWithSizeSuffix(product.image_src, IMAGE_SIZES.XSMALL) : '';
  const producerStatus = path(['producer', 'status'], product);
  const producerNotAccepted = isDefined(producerStatus) && producerStatus === SUPERUSER_APPROVAL.AWAITING_APPROVAL;
  const primaryTitle = product?.title || 'NO TITLE';
  return (
    <TableRow>
      <TableCell>
        {/* {primaryTitle} */}
        <ListItem>
          <ListItemAvatar>
          {producerNotAccepted ? (
              <RedBlinkingBadge
                overlap="circle"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                variant="dot"
              >
                <Avatar>
                  <img src={image} alt="P" />
                </Avatar>
              </RedBlinkingBadge>
            ) : (
              <GreenBlinkingBadge
                overlap="circle"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                variant="dot"
              >
                <Avatar>
                  <img src={image} alt="P" />
                </Avatar>
              </GreenBlinkingBadge>
            )}
          </ListItemAvatar>
          <ListItemText
            style={producerNotAccepted ? { color: COLORS.mainRed } : {}}
            primary={primaryTitle}
          />
        </ListItem>
      </TableCell>
      <TableCell>
        <HighlightedText text={brand} searchString={textQuery} />
      </TableCell>
      <TableCell>
        {product?.EAN?.trim() !== '' && <p>{product.EAN}</p>}
        {product?.EAN2?.trim() !== '' && <p>{product.EAN2}</p>}
      </TableCell>
      <TableCell>{product?.apiExportAt && format(parseInt(product.apiExportAt), 'Y-MM-dd HH:mm')}</TableCell>
      <TableCell>
        {historyLength > 0 && (
          <Button color="primary" variant="contained" size="small" onClick={() => { setCurrentProduct(product); setOpen(true); }}>
            History ({historyLength})
          </Button>
        )}
        {historyLength === 0 && <span style={{color: 'red'}}>No export history</span>}
      </TableCell>
    </TableRow>
  );
};

export default HistoryItem;
