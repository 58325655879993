import React, { useMemo } from 'react';
import IProduct from '../../../../models/product';
import InputLabel from '../../../inputfields/InputLabel';
import FloatInput from '../FloatInput';
import { checkDecimal } from './gross-dimensions';
import ThreeWaySwitch from 'components/inputfields/ThreeWaySwitch';

export const checkErrorGrossWeightAndNetWeight = (productState: IProduct) => {
  const grossWeightValue = productState.gross_weight_num;
  const descriptiveSizeAmount = productState.descriptive_size_amount;
  const descriptiveSizeUnit = productState.descriptive_size_unit || '';
  const isCurrentGramUnit = ['KILOGRAM', 'GRAM', 'GRAM_APPROXIMATE'].includes(descriptiveSizeUnit);

  if (!isCurrentGramUnit || !grossWeightValue || !descriptiveSizeAmount) {
    return false;
  }

  if (descriptiveSizeUnit === 'KILOGRAM') {
    return Number(grossWeightValue) <= descriptiveSizeAmount * 1000;
  }

  return Number(grossWeightValue) <= descriptiveSizeAmount;
};

export const checkGrossWeightError = (productState: IProduct) => {
  if (checkDecimal(productState.gross_weight_num)) {
    return 'Use only whole numbers for weight';
  }
  if (checkErrorGrossWeightAndNetWeight(productState)) {
    return `Gross weight must be larger than net weight (currently ${productState.descriptive_size_amount})`;
  }
  return '';
}

interface IGrossWeightProps {
  approve: boolean;
  state: IProduct;
  setState: (state: IProduct) => void;
  showLabel?: boolean;
}

const GrossWeight = ({ approve, state, setState, showLabel }: IGrossWeightProps) => {
  const error = useMemo(() => {
    return checkGrossWeightError(state);
  }, [state]);

  const canApprove = state?.approve?.find((x) => x.field === 'gross_weight_num');

  return (
    <>
    {showLabel && (
      <InputLabel
        heading='Bruttovikt'
        text='Ange produktens bruttovikt inklusive allt innehåll och förpackningsmaterial. Vikten anges i gram. Ex. För en flaska anges hela produktens vikt, inklusive glas/plast-förpackning.'
        required
      />
    )}
    <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
      <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
        <FloatInput
          forceRed={!!error}
          name="gross_weight_num"
          label="Bruttovikt"
          state={state}
          setState={setState}
          dense={false}
          // withLabel
          // labelOptions={{
          //   heading: 'Bruttovikt',
          //   text: 
          // }}
          style={{ maxWidth: 150, width: '100%', flex: 1 }}
        />
        {showLabel && (
          <h3 style={{alignSelf: 'center'}}>&nbsp;gram</h3>
        )}
      </div>

      {approve && canApprove && (
        <ThreeWaySwitch
          name="gross_weight_num"
          state={state}
          setState={setState}
        />
      )}
    </div>
    {!!error && (
      <span style={{ fontWeight: 'bold', color: 'orangered' }}>
        {error}
      </span>
    )}
    </>
  );
};

export default GrossWeight;
