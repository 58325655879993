import axios from 'axios';
import { decodeImage, decode, toRGBA8 } from 'utif';
import imageCompression from 'browser-image-compression';

import { restEndpoints } from 'constants/domain';

interface ITiffData {
  width: number;
  height: number;
  size?: number;
  rgba?: Uint8Array;
}

const getSize = async (fileSrc?: string) => {
  if (!fileSrc) return 0;

  try {
    const response = await fetch(fileSrc);
    const blob = await response.blob();
    return blob.size || 0;
  } catch {
    return 0;
  }
}

const getTiffData = async (fileSrc?: string) => {
  let width = 0;
  let height = 0;
  let size = 0;
  let rgba: Uint8Array | undefined;
  if (fileSrc) {
    size = await getSize(fileSrc);
    const pp = await fetch(fileSrc);
    if (pp) {
      const qq = await pp.arrayBuffer();
      if (qq) {
        const ifds = decode(qq);
        if (ifds && ifds.length > 0) {
          decodeImage(qq, ifds[0]);
          const result = ifds[0];
          width = result.width;
          height = result.height;
          rgba = toRGBA8(result);
        }
      }
    }
  }
  if (!width) width = 0;
  if (!height) height = 0;
  const result: ITiffData = {
    width,
    height,
    size,
    rgba
  };
  return result;
};

export const getDimensions = async (fileSrc?: string, isTiff = false) => {
  if (!fileSrc) return { width: 0, height: 0, size: 0};
  if (isTiff) {
    return await getTiffData(fileSrc);
  } else {
    const size = await getSize(fileSrc);
    return new Promise<ITiffData>((res, rej) => {
      const i = new Image();
  
      i.onload = () => {
        const result: ITiffData = { width: i.width, height: i.height, size };
        res(result);
      };
  
      i.onerror = () => {
        const result: ITiffData = { width: 0, height: 0, size };
        rej(result);
      };
  
      i.src = fileSrc;
    });
  }

  // const result = await probe(pp.body.getReader());
  // console.log('!!!result', result);
  // console.log('!!!dimensions', width, height);
};

interface RejectedFile {
  file: File;
  width: number;
  height: number;
}

export const dimensionsCheck = async (files: File[]) => {
  const acceptedByDimension: File[] = [];
  const rejectedByDimension: RejectedFile[] = [];

  for (var i = 0; i < files.length; i++) {
    const f = files[i];

    const fileSrc = URL.createObjectURL(f);
    console.log('fileSrc', fileSrc);
    const { width, height } = await getDimensions(fileSrc, f.type === 'image/tiff');
    if (width > 899 && height > 899) acceptedByDimension.push(f);
    else rejectedByDimension.push({ file: f, width, height });

    URL.revokeObjectURL(fileSrc);
  }
  return { acceptedByDimension, rejectedByDimension };
};

interface FileWithPreview extends File {
  path?: string;
  preview?: string;
}

export const processImage = async (file: FileWithPreview, token: string, addWatermark?: boolean) => {
  if (!file) return undefined;
  if (!addWatermark && file.type !== 'image/tiff') return file;
  console.log('!!!oldFile', file);

  const form = new FormData();
  form.append('image_file', file);
  if (addWatermark) {
    form.append('add_watermark', '1');
  }
  const response = await axios.post(
    restEndpoints.tiff,
    form,
    {
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
        add_watermark: addWatermark ? '1' : undefined,
      },
      responseType: 'arraybuffer',
    }
  );
  const data  = response?.data;
  if (data) {
    let newPath = '';
    if (file.path) {
      const ext = file.path.split('.').pop();
      newPath = file.path.split('.')[0];
      newPath += file.type === 'image/tiff' ? '.png' : `.${ext}`;
    }

    const mime = file.type === 'image/tiff' ? 'image/png' : file.type;
    console.log('!!!newPath mime', mime);
    console.log('!!!newPath', newPath);
    const newFile: FileWithPreview = new File([data], newPath, { type: mime });
    return newFile;
  }
  return undefined;
};

export const tiffToPng = async (file: FileWithPreview, token: string) => {
  if (file && file.type === 'image/tiff') {
    console.log('!!!oldFile', file);
    
    const form = new FormData();
    form.append('image_file', file);
    const response = await axios.post(
      restEndpoints.tiff,
      form,
      {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: 'arraybuffer',
      }
    );
    const data  = response?.data;
    
    // const blob = await convertTiffToPng(file);
    if (data) {
      // const newPath = path.parse(file.path).name + '.png';
      const newPath = file.path ? file.path.split('.')[0] + '.png' : '';
      const newFile: FileWithPreview = new File([data], newPath, { type: 'image/png' });
      if (newFile) {
        newFile.path = newPath;
        newFile.preview = URL.createObjectURL(newFile);
      }
      return newFile;
    }
  } else {
    return file;
  }
  return undefined;
};

export const compressImage = async (file: File, maxSizeMB = 10) => {
  if (!file) return file;
  if (file.size < maxSizeMB * 1024 * 1024) return file;

  try {
    const compressedFile = await imageCompression(file, { maxSizeMB });
    return compressedFile;
  } catch (error) {
    console.error('>>>> compressImage Error', error);
    return;
  }
}

export const convertTiffToPng = async (file: File) => {
  if (!file || file.type !== 'image/tiff') return file;

  const fileSrc = URL.createObjectURL(file);

  try {
    const { width, height, rgba } = await getTiffData(fileSrc);  
    if (!rgba) return;

    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");
    const imgData = new ImageData(
      new Uint8ClampedArray(rgba.buffer),
      width,
      height,
    );
    ctx?.putImageData(imgData, 0, 0);
    const blob = await new Promise(resolve => canvas.toBlob(resolve));
    const newName = file.name ? file.name.split('.')[0] + '.png' : '';
    return new File([blob as Blob], newName, { type: 'image/png' });
  } catch (error) {
    console.error('>>>> convertTiffToPng Error', error);
    return;
  }
};

export const addAutoEnhanceParam = (url: string) => {
  if (url.trim() !== '') {
    const newUrl = new URL(url);
    newUrl.searchParams.set("auto", "enhance");
    return decodeURI(newUrl.href);
  }
  return url;
};

export const addWatermarkParam = (url: string) => {
  if (url.trim() !== '') {
    const newUrl = new URL(url);
    newUrl.searchParams.set("wm", "1");
    return decodeURI(newUrl.href);
  }
  return url;
};
