import { FC, useMemo } from 'react';
import { isNil } from 'lodash';

import IProduct from 'models/product';
import { isBlank } from 'utils/helpers-ts';
import TagLabel from 'components/TagLabel';
import { scrollWithOffset } from './alerts';

export const getRequireErrorList = (state?: IProduct) => {
  const errorList: { field: keyof IProduct; label: string }[] = [];

  if (!state) return errorList;

  if (isBlank(state.title)) {
    errorList.push({ field: 'title', label: 'Produktnamn' });
  }
  if (isBlank(state.EAN)) {
    errorList.push({ field: 'EAN', label: 'EAN-kod' });
  }
  if (isNil(state.descriptive_size_amount) || state.descriptive_size_amount <= 0) {
    errorList.push({ field: 'descriptive_size_amount', label: 'Nettokvantitet Mängd' });
  }
  if (isBlank(state.descriptive_size_unit)) {
    errorList.push({ field: 'descriptive_size_unit', label: 'Nettokvantitet Enhet' });
  }
  return errorList;
};

export const checkRequireError = (state?: IProduct) => {
  const errorList = getRequireErrorList(state);
  return !!errorList.length;
};

interface CheckRequireErrorProps {
  state?: IProduct;
}

const CheckRequireError: FC<CheckRequireErrorProps> = ({ state }) => {
  const errorList = useMemo(() => {
    return getRequireErrorList(state);
  }, [state]);

  return (
    <>
      {errorList.map(({ field, label }) => {
        return (
          <TagLabel key={field} space type="error" onClick={() => scrollWithOffset(field)}>
            <span>{label} är obligatoriskt:</span>
            <span style={{ marginLeft: 4, fontWeight: 400 }}>Klicka här</span>
          </TagLabel>
        );
      })}
    </>
  );
};

export default CheckRequireError;
