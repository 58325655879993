import React from 'react';
import { path } from 'ramda';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { FormHelperText, InputAdornment, ListSubheader, MenuClassKey, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { isDefined } from '../../utils/helpers';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

const useStyles = makeStyles(theme => ({
  selectPaper: {
    '& .MuiMenu-list': {
      paddingTop: 0,
    },
  },
  searchWrapper: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    padding: '8px 16px',
    backgroundColor: '#ffffff',
  },
}));

export interface IListItem {
  value?: string | number;
  key?: string;
  displayName?: string;
  suggested?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
}

interface IListDropDownProps {
  fullWidth?: boolean;
  size?: 'small' | 'medium';
  label?: string;
  listData?: IListItem[];
  handleChange?: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => void;
  value?: any;
  targetName?: string;
  style?: React.CSSProperties;
  formControlDownStyle?: React.CSSProperties;
  dropDownStyle?: Partial<ClassNameMap<MenuClassKey>>;
  displayEmpty?: boolean;
  disableLabel?: boolean;
  showSuggested?: boolean;
  showSearch?: boolean;
  errorText?: string;
  disabled?: boolean;
  required?: boolean;
  background?: string;
  forceRed?: boolean;
  onOpen?: () => void;
}

const ListDropDown = ({
  fullWidth = true,
  size = 'medium',
  label,
  listData,
  handleChange: handleChangeProp,
  errorText,
  value,
  targetName,
  style,
  formControlDownStyle,
  dropDownStyle,
  displayEmpty,
  disableLabel,
  showSuggested,
  showSearch,
  disabled,
  required,
  background = '#FFFFFF',
  forceRed,
  onOpen,
}: IListDropDownProps) => {
  const classes = useStyles();
  const searchWrapperRef = React.useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');

  let suggested: IListItem[] = [];
  if (showSuggested && listData) {
    suggested = listData.filter(x => x.suggested);
  }
  const containsText = (text?: string, searchText?: string) => {
    if (!searchText || searchText.trim() === '') {
      return true;
    }
    if (!text || text.trim() === '') {
      return false;
    }
    return text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
  };
  const filteredData = React.useMemo(() => {
    if (!listData) {
      return [];
    }
    if (!searchText || searchText.trim() === '') {
      return listData;
    }
    return listData?.filter(x => containsText(x?.displayName, searchText));
  }, [listData, searchText]);
  const isError = !!forceRed || !!(errorText && errorText.trim() !== '');

  const handleOpen = () => {
    setSearchText('');
    setOpen(true);
    onOpen?.();
  };

  const handleClose = (event: React.ChangeEvent<{}>) => {
    if (!searchWrapperRef.current?.contains(event.nativeEvent.target as Node)) {
      setOpen(false);
    }
  };

  const handleChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    child: React.ReactNode
  ) => {
    if (!searchWrapperRef.current?.contains(event.nativeEvent.target as Node)) {
      handleChangeProp?.(event, child);
    }
  };

  const fontSize = size === 'small' ? 14 : 16;

  return (
    <div style={style}>
      <FormControl
        fullWidth={fullWidth}
        size={size}
        variant="outlined"
        error={isError}
        disabled={disabled}
        required={required}
        style={{ background, marginBottom: '10px', ...formControlDownStyle }}
      >
        {!disableLabel && (
          <InputLabel
            id="simple-select-outlined-label"
          >
            {label}
          </InputLabel>
        )}

        <Select
          // error
          labelId="simple-select-outlined-label"
          id={targetName}
          value={value}
          open={open}
          onChange={handleChange}
          onOpen={handleOpen}
          onClose={handleClose}
          label={label}
          displayEmpty={displayEmpty}
          name={targetName}
          // dropDownStyle={dropDownStyle}
          MenuProps={{
            classes: dropDownStyle, // TODO check
            autoFocus: !!value,
            PaperProps: {
              className: showSearch ? classes.selectPaper : undefined,
            },
          }}
          SelectDisplayProps={{ style: { fontSize } }}
        >
          {showSearch && (
            <div ref={searchWrapperRef} className={classes.searchWrapper}>
              <TextField
                fullWidth
                size="small"
                autoFocus={!value}
                variant="outlined"
                placeholder="Type to search..."
                InputProps={{
                  autoComplete: 'new-password', // disable autocomplete and autofill (off not working in Chrome)
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  style: { fontSize },
                }}
                onChange={e => setSearchText(e.target.value)}
                onKeyDown={e => {
                  if (e.key !== 'Escape') {
                    // Prevents autoselecting item while typing (default Select behaviour)
                    e.stopPropagation();
                  }
                }}
              />
            </div>
          )}
          {displayEmpty && (
            <MenuItem value={undefined} style={{ fontSize }}>
              <em style={{ visibility: 'hidden' }}>None</em>
            </MenuItem>
          )}
          {showSuggested && suggested && suggested.length > 0 && (
            <>
              <ListSubheader disableSticky={true}>Suggested</ListSubheader>
              {suggested.map(i => (
                <MenuItem value={i.value} key={i.value} style={{ fontSize }}>
                  <em>{isDefined(path(['displayName'], i)) ? i.displayName : i.value}</em>
                </MenuItem>
              ))}
            </>
          )}
          {showSuggested && suggested && suggested.length > 0 && (
            <ListSubheader disableSticky={true} style={{ fontSize }}>
              Other
            </ListSubheader>
          )}
          {filteredData &&
            filteredData.map(i => (
              <MenuItem value={i.value} key={i.key || i.value} disabled={i.disabled} style={{ fontSize, ...i.style }}>
                <em>{isDefined(path(['displayName'], i)) ? i.displayName : i.value}</em>
              </MenuItem>
            ))}
        </Select>
        {isError && <FormHelperText>{errorText}</FormHelperText>}
      </FormControl>
    </div>
  );
};

export default ListDropDown;
