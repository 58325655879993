import { FC } from 'react';
import { makeStyles } from '@material-ui/core';
import useViewport from 'hooks/useViewports';
import TagLabel from 'components/TagLabel';
import { InfoOutlined } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
    margin: '8px 0 16px 0',
  },
}));

interface UploadImageGuideLabelsProps {
  title: string;
  labels: string[];
}

const UploadImageGuideLabels: FC<UploadImageGuideLabelsProps> = ({ title, labels }) => {
  const { smUp } = useViewport();
  const classes = useStyles();

  return (
    <div>
      <p style={{ fontWeight: 600, marginBottom: 4 }}>{title}</p>
      <div className={classes.wrapper} style={{ flexDirection: smUp ? 'row' : 'column', alignItems: 'start' }}>
        {labels.map((label, index) => {
          return <TagLabel key={index} type="info" label={label} icon={<InfoOutlined fontSize="small" />} />;
        })}
      </div>
    </div>
  );
};

export default UploadImageGuideLabels;
