import { FC, useEffect, useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from '@material-ui/core';
import Box from '@material-ui/core/Box';

export type CopyEmailData = {
  to: string;
  cc: string;
  subject: string;
  body: string;
};

interface CopyEmailInviteProducerProps {
  open: boolean;
  data: CopyEmailData;
  onClose: () => void;
}

const CopyEmailInviteProducer: FC<CopyEmailInviteProducerProps> = ({ open, data, onClose }) => {
  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopy = () => {
    setCopySuccess(false);
    navigator.clipboard
      .writeText(`Till: ${data.to}\nCC: ${data.cc}\nÄmne: ${data.subject}\n\n${data.body}`)
      .then(() => setCopySuccess(true));
  };

  useEffect(() => {
    if (copySuccess) {
      setTimeout(() => {
        setCopySuccess(false);
      }, 2000);
    }
  }, [copySuccess]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Kopiera e-postmall</DialogTitle>

      <DialogContent>
        <div>
          <p style={{ marginTop: 0 }}>
            Vi har genererat ett e-postmeddelande åt dig nedan. Du kan kopiera innehållet och klistra in det i din
            föredragna e-postklient.
          </p>
        </div>

        <Box fontSize={16} mb={1}>
          E-postmall
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gridGap={8}
          bgcolor="#f8f9fa"
          p={2}
          border="1px solid #eee"
          borderRadius={4}
        >
          <Box>
            <b>Till:</b> {data.to}
          </Box>
          <Box>
            <b>CC:</b> {data.cc}
          </Box>
          <Box>
            <b>Ämne:</b> {data.subject}
          </Box>

          <div style={{ background: '#fff', border: '1px solid #ccc', whiteSpace: 'pre-line', padding: 8 }}>
            {data.body}
          </div>
        </Box>
      </DialogContent>

      <DialogActions>
        <Box position="relative" display="flex" gridGap={16} px={2} py={1}>
          <Button variant="outlined" onClick={onClose}>
            Stäng
          </Button>

          <Tooltip open={copySuccess} title="✓ Kopieras" placement="top">
            <Button variant="contained" color="primary" onClick={handleCopy}>
              KOPIERA E-POSTMALL
            </Button>
          </Tooltip>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default CopyEmailInviteProducer;
